import { Container, Grid, Skeleton } from '@mui/material';
import AvatarContainer from '../components/AvatarContainer';

const instructors = [
  {
    name: 'Kelsey Copas',
    url: '/instructors/kelsey.png',
    bio: 'Kelsey started teaching Spin Classes when she was just 18 years old, and several years later (we’re not going to talk about how many😂) she fulfilled her dreams of opening her own fitness studio! With a Bachelors of Science in Kinesiology and certifications from Mad Dogg Athletics Spinning®, ASFA®, Barre Intensity, and more, she is constantly looking for ways to continue her education in helping others achieve their fitness goals! She loves her community and thrives off seeing her clients grow, and own their own strength. She specializes in Cycle, Barre, and being a Mama to the greatest little girl in all the land. Her husband Drew is the other half of Copas Crew Fitness, and their family is dedicated to giving Benicia the best place to feel safe in their fitness journey! She can’t wait to see you move and groove in her classes!',
  },
  {
    name: 'Abel',
    url: '/instructors/abel.jpeg',
    bio: "Abel considers himself a huge nerd in respect to all things involving human movement. As a physical therapist and yoga instructor, he is passionate about helping people resolve or avoid pain all while having as much enjoyment for as long as possible. At his core, he considers himself an educator, and loves empowering people with tools and the understanding of how to feel and move their best.  Abel and his family moved to Benicia in 2020 and consider it the best decision they've ever made. Outside of the studio and his physical therapy practice, he loves exploring this town and the greater outdoors with his family!",
  },
  {
    name: 'Candice',
    url: '/instructors/candice.png',
    bio: 'Candice has always been active since a young age, playing soccer and surfing where she grew up in Southern California. She moved to Vallejo/Benicia in 2013 and started taking spin classes in 2015. She loved it so much she decided to get certified and became a Cycle instructor in 2016! She loves music, and believes a great workout requires a great soundtrack. If she isn’t at the studio she is watching her kids play sports or at the beach.',
  },
  {
    name: 'Carly',
    url: '/instructors/carly.png',
    bio: 'Carly has been teaching yoga formally for two years and off the record for years before that. She is a nurse practitioner, a mother of two tiny girls, a singer/songwriter and seamstress. Because, why not? She strives to make yoga fun and active while honoring its roots and incorporating meditation and mindfulness into her high energy, athletic flows.',
  },
  {
    name: 'Chelsea',
    url: '/instructors/chelsea.png',
    bio: 'Chelsea grew up in Benicia and lives here now with her husband and 2 kids. Health and fitness have always been a big part of her life. She’s a Registered Nurse and also has a B.S. in Clinical Nutrition from UC Davis! She played softball growing up and played for Benicia High School. Since then, she’s tried all different types of workouts and fitness classes but struggled to find one that she loved enough to stick with, until she discovered Barre! She’s now been taking Barre classes for almost 10 years and took the leap into teaching in July of 2023 after being certified through Barre Intensity! Besides fitness she also loves to cook, garden, read, and listen to music and true crime podcasts!',
  },
  {
    name: 'Danielle',
    url: '/instructors/danielle.png',
    bio: 'Danielle has been teaching Spin classes since 2015 and Barre classes since 2023, but has been taking those formats for much longer! She has always been involved in sports and is a lifelong athlete. In addition to teaching Cycle and Barre, Danielle is also an educator and works for Contra Costa County Office of Education. She lives in Pleasant Hill with her husband and daughter, and enjoys staying active by taking Barre, Strength, Spin and Yoga classes as well as running in her free time. She loves teaching and can`t wait to sing with you in one of her Cycle or Barre classes soon!',
  },
  {
    name: 'Dawn',
    url: '/instructors/dawn.png',
    bio: 'Bio coming soon!',
  },
  {
    name: 'Lani',
    url: '/instructors/lani.png',
    bio: 'Lani discovered movement at a young age and has a strong background in group sports. When she moved to Vallejo in 2013 her love of fitness really blossomed and became a state of mind. Since then, she has become a certified Indoor Cycling Instructor, a certified BUTI Yoga teacher, and she completed her 200 Hour Yoga Teacher Training in August 2023. When she’s not on the bikes or practicing yoga, she’s probably exploring a beach with her husband or reading a good book with her two cats. She takes pride in the Vallejo/Benicia community and truly enjoys sharing her love of music and movement with everyone that takes her classes!',
  },
  {
    name: 'Leigh',
    url: '/instructors/leigh.png',
    bio: 'Leigh has been going to Barre classes since 2014 and fell in love with how barre made her feel and look; so in 2019 she took the plunge and became a teacher! She loves mixing up her classes with the ball, blocks, and bands all with fun music. She wants you to go away from class feeling like you exercised your whole body and relaxed at the same time! She’s been a labor and delivery nurse for close to 25 years so understands the body and it’s movement. Let her help you reach your goals!',
  },
  {
    name: 'Mary',
    url: '/instructors/mary.jpg',
    bio: 'Bio coming soon!',
  },
  {
    name: 'Melina',
    url: '/instructors/melina.png',
    bio: 'Bio coming soon!',
  },
  {
    name: 'Monica',
    url: '/instructors/monica.png',
    bio: 'Monica has practiced yoga asana regularly since the early 2000s - at first for a college P.E. credit, then returning to the mat again and again to enjoy mind-body connection and intuitive movement. Connecting passions of teaching, learning, and holistic well-being, Monica earned her master`s degree in education policy and leadership at Stanford, and now works from home full-time for the School of Medicine. Following her 200-hour yoga teacher training in 2022, she has also pursued continuing education in yin and prenatal yoga. Outside of the studio, Monica loves to spend time outdoors with her husband and three dogs.',
  },
  {
    name: 'Promita',
    url: '/instructors/promita.png',
    bio: 'Promita discovered yoga in her quest to become more connected to her Indian roots. She began her journey in 2007 and has been practicing yoga regularly ever since! She is a registered instructor through the YogaFit program and has taught at multiple studios in Northern California. She was born and raised in San Jose, CA until the age of twelve, then her family moved to Kolkata, India. Living in India for seven years proved to be an eye opening and humbling experience and provided her with a deeper sense of appreciation and passion for her yoga practice. She then came back to Los Angeles to pursue a degree in Chemical Engineering and has worked for several years in the oil and gas industry. Promita is grateful to provide students with the opportunity to feel empowered by their practice!',
  },
  {
    name: 'Rebecca',
    url: '/instructors/rebecca.png',
    bio: 'Rebecca is a high energy cycle instructor who brings fun and engagement to class. Her outgoing personality helps to motivate everyone achieve a tremendous workout in every class!',
  },
  {
    name: 'Susannah',
    url: '/instructors/susannah.png',
    bio: 'Susannah is a Bay Area native who loves to move. At various times in her life she was a gymnast, a dancer, a diver, and a trapeze artist. She has been teaching Barre classes for 15+ years, and is committed to helping every student and client stay well and healthy in their bodies while gaining strength and confidence. Susannah lives in Benicia with her husband and son, and her day job is in green energy! Meet her at the Barre soon!',
  },
  {
    name: 'Wendi',
    url: '/instructors/wendi.png',
    bio: 'This beautiful CYT has found so much clarity on her yoga mat, and as a teacher. She’s dedicated to creating flows that engage not just the body, but the mind and soul for an experience that leaves your whole being feeling a little more connected, grounded and restored. Her 20 years of practice has evolved over the many phases of her life, so Wendi engages her yoga students at every level of skill, helping them find their deepest expression in every minute on the mat. You can expect great music, movement, and engaging intention in her classes.',
  },
  {
    name: 'Whitney',
    url: '/instructors/whitney.png',
    bio: 'Whitney has a passion for creating more accessible health and wellness opportunities. She gained a B.S. in Clinical Nutrition from UC Davis and has since been working with community health programs from rural Zambia all the way back to Solano County. Whitney works in the nonprofit sector aiming to improve food accessibility and nutrition education in Yolo and Solano County. In college, she stumbled into a yoga class and has cultivated an appreciation of this practice over the last decade. This led to a deeper dive in 2021, when she completed a 200 YTT to continue to expand on holistic approaches to health. Whitney enjoys creative sequences, the power of pranayama, and a good playlist. You can look forward to grounding down, tuning in, and exploring connection in her classes.',
  },
];

const ContainerStyle = {
  minHeight: '80vh',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 2,
};

const AvatarStyle = { width: 95, height: 95 };

const isLoading = false;

const Instructor = () => {
  return (
    <Container sx={ContainerStyle} maxWidth='lg' id='instructors'>
      <Grid container>
        {isLoading ? (
          <>
            {[...Array(16)].map((e, i) => (
              <AvatarContainer style={AvatarStyle}>
                <Skeleton
                  animation='wave'
                  variant='circular'
                  sx={AvatarStyle}
                />
              </AvatarContainer>
            ))}
          </>
        ) : (
          instructors.map((instructor) => (
            <AvatarContainer
              style={AvatarStyle}
              key={instructor.name}
              item={instructor}
            />
          ))
        )}
      </Grid>
    </Container>
  );
};

export default Instructor;
