import { useEffect } from 'react';

export default function useExternalScripts(url) {
  useEffect(() => {
    const script = document.createElement('script');

    script.setAttribute('src', url);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
}
