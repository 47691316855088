import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import { Footer, Navbar } from './components';
import { Home, Membership, Schedule, Instructor } from './pages';

const AppStyle = {
  minHeight: '80vh',
  textAlign: 'center',
};

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Container sx={AppStyle}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/instructors' element={<Instructor />} />
          <Route path='/membership' element={<Membership />} />
          <Route path='/schedule' element={<Schedule />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </Container>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
