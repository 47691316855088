import { Box, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const HomeStyle = {
  minHeight: '75vh',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const LogoStyle = {
  height: '50vmin',
  pointerEvents: 'none',
};

const Home = () => {
  return (
    <Container sx={HomeStyle}>
      <Box component='img' src='/copas-crew-logo.png' sx={LogoStyle}></Box>
      <br />
      <Button variant='outlined' component={Link} to='/schedule'>
        {'book classes'}
      </Button>
    </Container>
  );
};

export default Home;
