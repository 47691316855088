import useExternalScripts from '../hooks/useExternalScripts';

const Schedule = () => {
  useExternalScripts('https://www.wellnessliving.com/rs/skin-widget-static.js');
  return (
    <div>
      <div
        className='wl-widget'
        data='k_skin=251964&amp;k_business=413154'
      ></div>
      <br />
    </div>
  );
};

export default Schedule;
