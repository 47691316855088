import { useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Grid,
  Modal,
  Typography,
} from '@mui/material';

const GridStyle = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AvatarContainer = ({ children, item, style }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid sx={GridStyle} item xs={4} sm={4} md={3}>
      <IconButton onClick={handleOpen}>
        <Avatar sx={style} alt={item.name} src={item.url}>
          {children}
        </Avatar>
      </IconButton>
      {item ? item.name : ''}
      <Modal
        sx={{ overflow: 'scroll' }}
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={ModalStyle}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Meet {item.name}!
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            {item.bio}
          </Typography>
        </Box>
      </Modal>
    </Grid>
  );
};

export default AvatarContainer;
