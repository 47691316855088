import { Box, Grid, Link } from '@mui/material';
import {
  CallOutlined,
  EmailOutlined,
  LocationOnOutlined,
} from '@mui/icons-material';

const email = 'kelsey@copascrew.com';
const phone_number = '(707) 315-8133';

const Footer = () => {
  return (
    <Box component='footer'>
      <Grid container sx={{ mb: 2 }}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EmailOutlined />
          <Link
            target='_top'
            rel='noopener noreferrer'
            href={`mailto:${email}`}
            underline='hover'
            color='inherit'
          >
            {email}
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LocationOnOutlined />
          <Link
            href='https://www.google.com/maps/place/Rose+Center,+2134+Columbus+Pkwy,+Benicia,+CA+94510/@38.0816674,-122.1941858,20z/data=!4m6!3m5!1s0x80857216fd411d2b:0xf78b6f0e9a3175b2!8m2!3d38.0816165!4d-122.1938276!16s%2Fg%2F11c1z3n8yx?entry=ttu'
            target='_blank'
            underline='hover'
            color='inherit'
          >
            {'2134 Columbus Pkwy, Benicia, CA 94510'}
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CallOutlined />
          <Link href={`tel:${phone_number}`} underline='hover' color='inherit'>
            {phone_number}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
